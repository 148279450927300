export const DesignStrings = {
  ufFeedFlowRate: "UF Feed Flow Rate",
  automatic: "Automatic",
  ufTechnologySelection: "UF Technology Selection",
  ufTechnology: "UF Technology",
  designFlowFluxRates: "Design Instantaneous (Gross) Flux and Flow Rates",
  backwashFlush: "Backwash Flux",
  cebFlux: "CEB Flux",
  forwardFlushFlow: "Forward Flush Flow",
  airFlow: "Air Flow",
  aerationAirFlow: "Aeration Air Flow",
  cIPRecycleFlowRate: "CIP Recycle Flow Rate",
  miniCIPRecycleFlowRate: "mini-CIP Recycle Flow Rate",
  forwardFlush: "Forward Flush",
  uFSpecialFeatures: "UF Special Features",
  adjustpHAddOxidantCoagulant: "Adjust pH, Add Oxidant/Coagulant",
  cEBOnly: "CEB only",
  miniCIPOnly: "mini-CIP only",
  CEBAndMiniCIP: "CEB and mini-CIP",
  designCycleInterval: "Design Cycle Intervals",
  filtrationDuration: "Filtration Duration",
  miniCip: "mini-CIP",
  acidCEB: "Acid CEB",
  disinfectionCEB: "Disinfection CEB",
  membraneIntegrityTesting: "Membrane Integrity Testing",
  offlineTimeperUnit: "Offline Time per Unit",
  airScour: "Air Scour",
  drain: "Drain",
  bWRinseDrainBottom: "BW Rinse Drain Bottom",
  bWRinseDrainTop: "BW Rinse Drain Top",
  cebTemprature: "CEB Temperature",
  digramForFeedWater: "Use design temperature from feed water",
  waterSource: "Water Source",
  cebWaterSource: "CEB Water Source",
  mineralAcid: "Mineral Acid",
  organicAcid: "Organic Acid",
  alkaliOxidantCEB: "Alkali/Oxidant CEB",
  alkali: "Alkali",
  oxidant: "Oxidant",
  duration: "Duration",
  durations: "Durations",
  chemicalSoakingDuration: "Chemical Soaking Duration",
  filtratetoLevel: "Filtrate to Level",
  lumenFill: "Lumen Fill",
  backwashesbetweenAirScour: "Backwashes between Air Scour",
  backwashTemprature: "Backwash Temperature",
  backwashWaterSource: "Backwash Water Source",
  forwardFlushWaterSource: "Forward Flush Water Source",
  backwashProtocol: "Backwash Protocol",
  cIP: "CIP",
  filtrateFlux: "Filtrate Flux",
  cipRecycleFlowRateInfo:
    "The CIP Recycle Flow Rate refers to the amount of fluid entering the system through the CIP Inlet Pipe during the cleaning process. During this step, the flow divides into two parts: one part is  the filtrate and the other part is the concentrate. Both parts are then circulated back to the modules.",
  tocRejection: "TOC Rejection",
  tocRejectionCOD: "COD (as KMnO4) Rejection",
  tocRejectionTitle: "UF Organics Rejection",
  tocRejectionNote:
    "Note: This feature is used to account for organics rejection by UF membranes. A default value is given but users are encouraged to use a relevant figure to their system.",
};

export const IngeString = {
  topbackwash: "BW Drain Bottom",
  bottomBackwash: "BW Drain Top",
  cebTopBackwash: "CEB Injection Drain Bottom",
  cebBottomBackwash: "CEB Injection Drain Top",
  selectedConfRackLabel: "T-Rack™ Design",
  recommConfigSliderMinRange: "Min T-Rack™ Size Limit: ",
  recommConfigSliderMaxRange: " :Max T-Rack™ Size Limit",
  backwashProtocolVal: "Normal protocol (RCBW)",
};

export const DupontString = {
  topbackwash: "Top Backwash",
  bottomBackwash: "Bottom Backwash",
  cebTopBackwash: "Top Backwash",
  cebBottomBackwash: "Bottom Backwash",
  selectedConfRackLabel: "IP Rack Design",
  recommConfigSliderMinRange: "Min IP Rack Size Limit: ",
  recommConfigSliderMaxRange: " :Max IP Rack Size Limit",
  backwashProtocolVal: "Normal protocol",
};

export const ValidationStrings = {
  slsRecommendedRange: "SLS Recommended Range",
  cipMineralAcidConectration: "CIP Mineral Acid Concentration",
  cipOrganicAcidConcentration: "CIP Organic Acid Concentration",
  cipAlkaliConcentration: "CIP Alkali Concentration",
  cipOxidantConcentration: "CIP Oxidant Concentration",
  cipRecycle: "CIP Recycle",
  cipHeatingStep: "CIP Heating Step",
  cipSoak: "CIP Soak",
};

export const FolderStrings = {
  restoreFolder: "Restore Folder",
  folderCreateSuccess: "The folder has been successfully created.",
  folderExists: "A folder with this name already exists. Specify a different name.",
  renameFolder: "Rename Folder",
  folderName: "Folder Name",
  createNewFolder: "Create New Folder",
  folderNameLength: "Folder name must contain 3 to 200 characters",
  folderNameCannotBeBlank: "Folder name cannot be blank.",
  createFolder: "Create Folder",
  folderNameAlreadyExist: "Folder name already exist",
  specialCharactersNotAllowed: "Folder name can't contain special characters: \\ / : * ? \" < > | ",
};

export const IXStrings = {};

export const IXDStrings = {
  demineralization: "IX Demineralization",
  backwashFrequency: "Backwash Frequency (Cycles Between BW)",
  backwashExpansion: "Backwash Expansion (%)",
  backwashDuration: "Backwash Duration (min)",
};

export const IXMBStrings = {
  demineralization: "Mixed Bed",
  backwashFrequency: "MB Separation Backwash Frequency(Cycles)",
  backwashExpansion: "MB Separation Backwash Expansion",
  backwashDuration: "MB Separation Backwash Duration (min)",
  regenrationHoldDownFaction: "SBA/SAC Regeneration Flow Ration",
  WaterChemistryAdjustments: "Water Chemistry Adjustments",
};

export const ImportProjectStrings = {
  projectAlreadyExists: "Project already exists",
  projectNameKey: "diffgr:diffgram/NewDataSet/Project/ProjectName",
  errorParsingXML: "Error parsing XML",
  checkProjectDetails: "Check Project Details...",
  import: "Import",
  renameYourProject: "Rename your project",
  errorImportingFile: "Error importing your project",
  pleaseCheckTheFileName: "Please check the project name before importing.",
  editFileName: "Edit Project Name",
  projectNameExistDoYouWantToReplace: "Project name already exist, Do you want to replace?",
  projectReplaceConfirmation: "Are you really sure you want to replace the existing project with this one.",
  cancel: "Cancel",
  replace: "Replace",
  rename: "Rename",
  replaceProject: "Replace Project",
  errorExportingFile: "Error exporting your project",
  exporingProject: "Exporting Project",
  duplicatingProject: "Duplicating Project",
  successfulExport: "Project has been exported successfully!",
  inputFieldCannotBeEmpty: "Input field cannot be empty.",
  minLengthError: minLength => `Name must be ${minLength} characters long.`,
  projectWithNameAlreadyExists: truncatedProjectName => `Project with name ${truncatedProjectName} already exists`,
  saveAsDwpro: "Save as .dwpro",
  acceptedExtensions: "Only .dwpx or .dwpro format allowed",
  replaceProjectQuestionMark: "Replace project?",
  importProject: "Import Project",
  importProjectHelperText: "This might take time depending on your file size",
  whatToDoNext: "What do you want to do next?",
  uploadingProject: "Uploading project",
  successfullyImported: "Successfully imported project",
  invalidWaterSubTypeId: "Mismatch detected in water subtype",
  invalidWaterSubtypeDesc:
    "The imported file includes a custom pairing of water subtype with NTU, TOC, and TSS values, which isn’t supported in WavePro. Would you like to proceed and reset to the default configuration?",
  resetAndProcceed: "Reset & Proceed",
  cancelImport: "Cancel Import",
};
export const CaseManagementStrings = {
  cases: "Cases",
  manageCase: "Manage Case",
  addCase: "Add Case",
  addNewCase: "Add New Case",
  editCase: "Edit Case",
  deleteCase: "Delete Case",
  openCase: "Open Case",
  caseNo: "Case No.",
  more: "More",
  switchToCase: "Switch to Case",
  add: "Add",
  save: "Save",
  update: "Update",
  cancel: "Cancel",
  Okay: "Okay",
  caseName: "Case Name",
  caseNotes: "Case Notes",
  close: "Close",
  actions: "Actions",
  caseManagement: "Case Management",
  caseManagementEditDelete:
    "You can add, edit, delete and reorder cases here based on your preference. To reorder hold the row and drag it up and down.",
  moveUp: "Move Up",
  moveDown: "Move Down",
  deleteCaseWarning: "This step is irreversible and all your data within that case will be lost.",
  caseAdded: "Case added",
  caseNameExists: "Case name already exists",
  deleteCaseTitle: "Delete case?",
  addCaseLoader: "Adding case",
  updateCaseLoader: "Updating case",
  dragToReorder: "Reorder",
};

export const ToolTips = {
  ingeBackwash:
    "The normal protocol is the Reverse Combined Backwash Protocol (RCBW), which means that during the change from Backwash Drain Bottom to Backwash Drain Top both valves are open at the same time during 5 sec.",
  dupontBackwash: "Select BW protocol if specified module is suitable for more than one.",
};

export const ModulTypeStrings = {
  rack: "Rack",
  openPlatform: "Open Platform",
};

export const CommonButtonStrings = {
  cancel: "Cancel",
  okay: "Okay",
  delete: "Delete",
  createNewProject: "Create New Project",
  importProject: "Import Project",
  addToFolder: "Add to Folder",
  openProject: "Open Project",
  createNewFolder: "Create Folder",
  organiseYourProjects: "Organize your projects",
  supportedFileTypes: "Only .dwpx or .dwpro allowed",
  supportedTechnologies: "UF, IXMB, IXD",
  dismiss: "Dismiss",
  yes: "Yes",
  no: "No",
  edit: "Edit",
};

export const ProjectStrings = {
  deleteProject: "Delete project?",
  deleteNote: "For 30 days you can find this in the Deleted directory and restore",
  deleteNotePermanent: "This can't be undone, Do you want to permanently delete this project",
  renameThisProject: "Rename this project",
  projectNameAlreadyExist: "Project name already exist",
  projectLengthValidation: "Project name must contain 3 to 200 characters",
  projectInputFieldValidation: "Project name cannot be blank.",
  editedJustNow: "Edited just now",
  createJustNow: "Created just now",
};

export const PumpStrings = {
  totalEfficencyTooltip:
    "Total Efficiency takes into account pump and motor efficiency: Total Efficiency = Pump Efficiency * Motor Efficiency",
};

export const userProfileMenuStrings = {
  contactDupont: "Contact (dupont.com)",
};

export const projectInfoModalStrings = {
  defaultState: "Select State",
  defaultCountry: "Select Country",
  defaultMarketSegment: "Select Market Segment",
  defaultMarketSegmentFilter: "All",
};

export const CarouselStrings = {
  welcometoWavePro: "Welcome to WavePro",
  workingTogetherForFuture: "Working together for a clean-water future",
};

export const OpenProjectFilterLabels = {
  equal: "Equal",
  doesnotequal: "Does not equal",
  beginswith: "Begins with",
  endswith: "Ends with",
  contains: "Contains",
  doesnotcontains: "Does not contain",
};
